import ApiRequest from '~/lib/api-request';
import reject from './reject';

export default async function logout() {
  await new ApiRequest({
    endpoint: `/auth/logout`,
    refreshToken: false,
    cache: false,
    callback: (response) => {
      if (response?.status === 200 || response?.status === 201) {
        reject();
      }
    },
  }).get();
}
