import {
  BanknoteIcon,
  BookOpenIcon,
  Building2Icon,
  DiameterIcon,
  DraftingCompassIcon,
  DropletIcon,
  FactoryIcon,
  GaugeIcon,
  LineChartIcon,
  LucideIcon,
  SearchCheckIcon,
  SprayCanIcon,
  StoreIcon,
  TestTubesIcon,
  UsersIcon,
} from 'lucide-react';
import { IUser } from '~/types';

export type NavLinkProps = {
  title: string;
  label?: string;
  icon?: LucideIcon;
  to: string;
  parent: (typeof CATEGORIES)[number];
  priority: number;
};

const CATEGORIES = ['Manager', 'EIS', 'MRP', 'Assets'] as const;

const SERVER_MENU: { [key: string]: NavLinkProps } = {
  '1': {
    title: 'Customer',
    icon: Building2Icon,
    to: '/customer',
    parent: 'Manager',
    priority: 1,
  },
  '2': {
    title: 'Vendors',
    icon: StoreIcon,
    to: '/vendor',
    parent: 'Manager',
    priority: 2,
  },
  '3': {
    title: 'Drawing',
    icon: DraftingCompassIcon,
    to: '/drawing',
    parent: 'Manager',
    priority: 3,
  },
  '4': {
    title: 'Die',
    icon: DiameterIcon,
    to: '/die',
    parent: 'Manager',
    priority: 4,
  },
  '5': {
    title: 'Alloy',
    icon: TestTubesIcon,
    to: '/alloy',
    parent: 'Manager',
    priority: 5,
  },
  '6': {
    title: 'Press',
    icon: FactoryIcon,
    to: '/press',
    parent: 'Manager',
    priority: 6,
  },
  '17': {
    title: 'Anodizing',
    icon: DropletIcon,
    to: '/anodizing',
    parent: 'Manager',
    priority: 7,
  },
  '18': {
    title: 'Coating',
    icon: SprayCanIcon,
    to: '/coating',
    parent: 'Manager',
    priority: 8,
  },
  '7': {
    title: 'Recipe',
    icon: GaugeIcon,
    to: '/recipe',
    parent: 'EIS',
    priority: 1,
  },
  '19': {
    title: 'Cost',
    icon: BanknoteIcon,
    to: '/cost',
    parent: 'Manager',
    priority: 10,
  },
  '8': {
    title: 'Extrusion',
    icon: LineChartIcon,
    to: '/extrusion',
    parent: 'EIS',
    priority: 2,
  },
  '9': {
    title: 'Correction',
    icon: SearchCheckIcon,
    to: '/correction',
    parent: 'EIS',
    priority: 3,
  },
  '10': {
    title: 'Books',
    icon: BookOpenIcon,
    to: '/book',
    parent: 'Assets',
    priority: 1,
  },
  '11': {
    title: 'Users',
    icon: UsersIcon,
    to: '/user',
    parent: 'Manager',
    priority: 12,
  },
  '12': {
    title: 'Products',
    to: '/product',
    parent: 'MRP',
    priority: 1,
  },
  '13': {
    title: 'Sales Orders',
    to: '/sale',
    parent: 'MRP',
    priority: 2,
  },
  '14': {
    title: 'Purchase Orders',
    to: '/purchase',
    parent: 'MRP',
    priority: 2,
  },
  '15': {
    title: 'Inventory',
    to: '/inventory',
    parent: 'MRP',
    priority: 3,
  },
} as const;

export const createMenu = (credentials: IUser) => {
  /* credentials.menu returns an array [1,2,3,4,5,6,7,8,9,10,11]
  SERVER_MENU returns { 1: { title: 'Customer', icon: Building2Icon, to: '/customer'} ... etc}
  filter the SERVER_MENU if the credentials.s includes the key then return it as an array */
  const menu = Object.keys(SERVER_MENU)
    .filter((key) => credentials?.menu?.includes(key))
    /* sort the array by the index of the sortByCategory array */
    .sort(
      (a, b) =>
        CATEGORIES.indexOf(SERVER_MENU[a].parent) - CATEGORIES.indexOf(SERVER_MENU[b].parent),
    )
    // sort the array by priority
    .sort((a, b) => SERVER_MENU[a].priority - SERVER_MENU[b].priority)
    .map((key) => SERVER_MENU[key as keyof typeof SERVER_MENU]) as Array<NavLinkProps>;

  const categorize = (menu: Array<NavLinkProps>) => {
    const grouped = menu.reduce(
      (acc, item: any) => {
        if (!acc[item.parent]) {
          acc[item.parent] = [];
        }
        acc[item.parent].push(item);
        return acc;
      },
      {} as { [key: string]: any[] },
    );
    return grouped;
  };

  return {
    mobile: menu,
    desktop: categorize(menu),
  };
};

export default createMenu;
