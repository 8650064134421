import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Params } from 'react-router-dom';
import store, { userAtom } from '~/auth/store';
import ApiRequest, { IResponse } from '~/lib/api-request';

export const useFetch = <T>(
  endpoint: string,
  options?: Partial<AxiosRequestConfig>,
  suspend?: boolean,
  loadOnRefetch?: boolean,
) => {
  const [response, setResponse] = useState<IResponse<T>>();
  const [isFetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const initialized = useRef(false);
  const tenant = store.get(userAtom)?.active_tenant;
  const tenantRef = useRef(tenant);

  const [params, setParams] = useState<Partial<AxiosRequestConfig>>(options?.params || {});

  const handleParams = useCallback((params: Partial<Params>) => {
    initialized.current = false;

    setParams((prev) => {
      for (const key in params) {
        if (params[key] === '' || params[key] === null) {
          delete (prev as any)[key];
          delete (params as any)[key];
        }
      }

      return { ...prev, ...params };
    });
  }, []);

  const fetch = useCallback(async () => {
    if (loadOnRefetch) setFetching(true);
    new ApiRequest<T>({
      endpoint,
      callback: (res) => {
        setResponse(res);
        setFetching(false);
        setError(res.data.error === 'true');
      },
      ...options,
      params,
    }).get();
  }, [endpoint, options, params]); // Add endpoint here

  const changedParams = useRef(false);

  useEffect(() => {
    if (initialized.current) {
      changedParams.current = true;
    }
  }, [params]);

  useEffect(() => {
    if (suspend) {
      setFetching(false);
      return;
    }

    if (!initialized.current) {
      initialized.current = true;
      fetch();
    }
  }, [params, endpoint, suspend]); // Add fetch here

  // Effect to detect tenant changes and re-fetch data
  useEffect(() => {
    if (tenant !== tenantRef.current) {
      tenantRef.current = tenant;
      fetch();
    }
  }, [tenant, fetch]);

  return { response, isFetching, error, handleParams, mutate: fetch, params };
};
