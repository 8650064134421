import clearCache from '~/lib/clear-cache';
import Cookies from 'js-cookie';
import store, { userAtom } from './store';

export default async function reject() {
  clearCache();
  Cookies.remove('_auth_token');
  Cookies.remove('_refresh_token');
  store.set(userAtom, null);
}
