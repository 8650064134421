import store, { userAtom } from '~/auth/store';
import { useNavigate } from 'react-router-dom';

export default function useCredentials() {
  const user = store.get(userAtom);
  const nav = useNavigate();

  if (!user) {
    return nav('/login') as never;
  }

  return user;
}
