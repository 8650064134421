import store, { userAtom } from '~/auth/store';
import { IResponse } from '~/lib/api-request';
import clearCache from '~/lib/clear-cache';
import { IUser } from '~/types';
import Cookies from 'js-cookie';

export default async function setToken(
  response: IResponse<
    {
      access_token: string;
      refresh_token: string;
    } & IUser
  >,
) {
  if (response.data?.access_token && response.data?.refresh_token) {
    await clearCache(); // clear cache on login to prevent stale data
    // expires in 10 seconds for testing
    Cookies.set('_auth_token', response.data?.access_token, {
      secure: true,
      sameSite: 'Strict',
      path: '/',
    }); // set token to cookie
    Cookies.set('_refresh_token', response.data?.refresh_token, {
      secure: true,
      sameSite: 'Strict',
      path: '/',
    }); // set token to cookie
    store.set(userAtom, response.data?.data); // set user data to store
  }
  return response;
}
