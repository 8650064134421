import { IUser } from '~/types';
import { createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const store = createStore();

const getUser = () => {
  let user = localStorage?.getItem('user');
  try {
    user = JSON.parse(user || '');
  } catch (error) {
    localStorage?.removeItem('user');
    return null;
  }
  return user as IUser | null;
};

export const userAtom = atomWithStorage<IUser | null>('user', getUser());

export default store;
