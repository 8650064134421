import { IResponse } from '~/lib/api-request';
import responseToaster from '~/lib/response-toaster';
import { saveAs } from 'file-saver';

export default function dl({
  type,
  base64,
  fileName,
}: {
  type: string;
  base64: string;
  fileName: string;
}) {
  try {
    // Convert base64 to Uint8Array
    const binaryData = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
    // Create a Blob from the Uint8Array
    const blob = new Blob([binaryData], { type });
    // Trigger a download
    saveAs(blob, fileName);
  } catch (error) {
    console.error('Error during file download:', error);
    responseToaster({
      data: { message: 'Something went wrong. Please try again later.' },
    } as IResponse<unknown>);
  }
}
