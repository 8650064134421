import { toast } from 'sonner';
import { IResponse } from './api-request';

export const responseToaster = (response: IResponse<unknown>) => {
  if (response.data.error === 'false') {
    toast.success('Success!', {
      description: response.data.message,
    });
  } else {
    toast.error('Error!', {
      description: Array.isArray(response?.data?.message) ? response?.data?.message.join(', ') : response?.data?.message,
    });
  }
};

export default responseToaster;
