import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function truncate(str: string, len: number) {
  return str?.length > len ? str?.slice(0, len) + '...' : str;
}

export const sanitize = (form: FormData) =>
  Object.fromEntries(Array.from(form.entries()).map(([key, value]) => [key, value || null]));

export const round = (value: number, decimals = 4) =>
  (isNaN(value) ? 0 : Math.round(value * 10 ** decimals) / 10 ** decimals).toFixed(decimals);
