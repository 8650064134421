import reject from '~/auth/reject';
import setToken from '~/auth/set-token';
import store, { userAtom } from '~/auth/store';
import responseToaster from '~/lib/response-toaster';
import searchParams from '~/lib/search-params';
import { IAbstract, IMeta, IUser } from '~/types';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AxiosCacheInstance,
  CacheRequestConfig,
  buildWebStorage,
  setupCache,
} from 'axios-cache-interceptor';
import { ReasonPhrases, StatusCodes } from 'http-status-codes';
import Cookies from 'js-cookie';
import nProgress from 'nprogress';
import { ActionFunctionArgs, redirect } from 'react-router-dom';
import { sanitize } from './utils';

export const cachePrefix = 'eis-cache:';

export type IResponse<T> = AxiosResponse<{
  data: T;
  meta: IMeta;
  message: string;
  error: 'true' | 'false';
  statusCode: number;
  access_token?: string;
  refresh_token?: string;
  context: {
    me: IUser;
  };
}>;

export type ApiRequestOptions<T> = Partial<AxiosRequestConfig> & {
  endpoint: string;
  invalidate?: string[];
  preprocess?: () => any;
  callback?: (res: IResponse<T>) => any;
  cache?: boolean;
  refreshToken?: boolean;
};

/* to prevent multiple cache instances on hot reload */
const AxiosInstance = Axios as unknown as AxiosCacheInstance;
const axios = AxiosInstance.defaults.cache
  ? AxiosInstance
  : setupCache(Axios, {
      storage: /* use localStorage for cache */ buildWebStorage(localStorage, cachePrefix),
    });

export default class ApiRequest<T> {
  private _url = '/api';
  private _endpoint = '';
  private _method = 'GET';
  private _headers = {} as AxiosRequestConfig['headers'];
  private _data = {};
  private _params = {};
  private _timeout = 10000;
  private _preprocess = async () => {};
  private _callback = (res: IResponse<T>) => res;
  private _invalidate: string[] = [];
  private _id = this._endpoint + JSON.stringify(this._params);
  private _cache = false;
  private _refreshToken = true;

  constructor(options: ApiRequestOptions<T>) {
    this._url = options?.url ?? this._url;
    this._endpoint = options?.endpoint ?? this._endpoint;
    this._method = options?.method ?? this._method;
    this._headers = options?.headers ?? this._headers;
    this._data = options?.data ?? this._data;
    this._params = options?.params ?? this._params;
    this._timeout = options?.timeout ?? this._timeout;
    this._preprocess = options?.preprocess ?? this._preprocess;
    this._callback = options?.callback ?? this._callback;
    this._invalidate = options?.invalidate ?? this._invalidate;
    this._id = this._endpoint + JSON.stringify(this._params);
    // this._cache = options?.cache ?? this._cache;
    this._refreshToken = options?.refreshToken ?? this._refreshToken;
  }

  private setHeaders(headers: AxiosRequestConfig['headers']) {
    this._headers = { ...this._headers, ...headers };
    return this;
  }

  private getToken() {
    const token = Cookies.get('_auth_token');
    if (token) this.setHeaders({ Authorization: `Bearer ${token}` });
    return this;
  }

  private cacheOptions(): CacheRequestConfig['cache'] {
    if (!this._cache) return false;

    /* if params are changing invalidate cache so pagination works as intended */
    if (Object.values(this._params).some((param) => param)) {
      axios.storage.remove(this._id);
    }

    if (this._method !== 'GET') {
      return {
        ttl: /* 1 hour */ 1000 * 60 * 60,
        cachePredicate: {
          statusCheck: /* only cache successful responses */ (status) => status === StatusCodes.OK,
        },
        methods: ['get'],
        update: {
          [this._id]: 'delete',
          /* invalidate every path passed from this._invalidate array - {} is used to match default endpoint with no params */
          ...this._invalidate.reduce((acc, curr) => ({ ...acc, [curr + '{}']: 'delete' }), {}),
        },
      };
    }
  }

  public async process() {
    /* preprocess data before sending */
    if (this._preprocess) await this._preprocess();

    /* set token if exists */
    this.getToken();

    axios.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error: { config: any; response: { status: number } }) => {
        const originalRequest = error.config;

        if (
          error.response.status === StatusCodes.UNAUTHORIZED &&
          originalRequest.url.endsWith('/auth/refresh')
        ) {
          reject();
          return Promise.reject(error);
        }

        if (
          error.response.status === StatusCodes.UNAUTHORIZED &&
          !originalRequest._retry &&
          this._refreshToken
        ) {
          originalRequest._retry = true;
          const refresh_token = Cookies.get('_refresh_token');
          const res = await axios.get(this._url + '/auth/refresh', {
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          });

          setToken(res);
          originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;

          return axios(originalRequest);
        }

        return Promise.reject(error);
      },
    );

    /* send request */
    const request = await axios({
      id: this._id,
      url: this._url + this._endpoint,
      method: this._method,
      headers: this._headers,
      data: this._data,
      params: this._params,
      paramsSerializer: (params: any) => {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      },
      timeout: this._timeout,
      cache: this.cacheOptions(),
    })
      /* handle response */
      .then((res) => {
        store.set(userAtom, res?.data?.context?.me); // always update user data on every request
        return this._callback(res);
      })
      /* handle error */
      .catch((err) => {
        nProgress.done();
        nProgress.remove();

        /* handle 403 errors when POSTing */
        if (err?.response?.status === StatusCodes.FORBIDDEN && this._method == 'GET') {
          throw new ResponseError(err?.response?.statusText ?? ReasonPhrases.FORBIDDEN)
            .setStatus(err?.response?.status ?? StatusCodes.FORBIDDEN)
            .setStatusText(err?.response?.statusText ?? ReasonPhrases.FORBIDDEN);
        }

        /* handle 404 errors */
        if (err?.response?.status === StatusCodes.NOT_FOUND) {
          throw new ResponseError(err?.response?.statusText ?? ReasonPhrases.NOT_FOUND)
            .setStatus(err?.response?.status ?? StatusCodes.NOT_FOUND)
            .setStatusText(err?.response?.statusText ?? ReasonPhrases.NOT_FOUND);
        }

        /* handle 500 errors */
        if (!err?.response) {
          throw new ResponseError(ReasonPhrases.INTERNAL_SERVER_ERROR)
            .setStatus(StatusCodes.INTERNAL_SERVER_ERROR)
            .setStatusText(ReasonPhrases.INTERNAL_SERVER_ERROR);
        }

        /* handle other errors */
        return this._callback(err?.response);
      });

    return request;
  }

  public get get() {
    this._method = 'GET';
    return this.process;
  }

  public get post() {
    this._method = 'POST';
    return this.process;
  }

  public get patch() {
    this._method = 'PATCH';
    return this.process;
  }

  public get put() {
    this._method = 'PUT';
    return this.process;
  }

  public get getAll() {
    this._method = 'GET';
    this._params = { pagination: 'off' };
    return this.process;
  }

  public get delete() {
    this._method = 'DELETE';
    return this.process;
  }
}

export class ResponseError extends AxiosError {
  public status: number = 500;
  public statusText: string = 'Internal Server Error';

  constructor(message: string) {
    super(message);
    this.name = 'ResponseError';
  }

  public setStatus(status: number) {
    this.status = status;
    return this;
  }

  public setStatusText(statusText: string) {
    this.statusText = statusText;
    return this;
  }
}

function isObj(data: IAbstract | IAbstract[]): data is IAbstract {
  return !Array.isArray(data);
}

export function api<T extends IAbstract | IAbstract[]>(
  request: Request,
  options: ApiRequestOptions<T>,
) {
  return {
    async get() {
      if (options.endpoint === 'off') return {} as IResponse<T>;
      try {
        return await new ApiRequest<T>({
          ...options,
          params: searchParams(request),
          callback: (response) => {
            if (options.callback) return options.callback(response);
            return response;
          },
        }).get();
      } catch (error) {
        throw error;
      }
    },
    async action(method: ActionFunctionArgs['request']['method']) {
      try {
        return await new ApiRequest<T>({
          ...options,
          method,
          callback: (response) => {
            responseToaster(response);
            if (response.status === StatusCodes.CREATED && isObj(response.data.data)) {
              return redirect(request.url.replace('create', response.data.data.id));
            }
            if (options.callback) return options.callback(response);
            return response;
          },
          data: options.data ?? sanitize(await request.formData()),
        }).process();
      } catch (error) {
        throw error;
      }
    },
  };
}
